import React from "react";
import "./BroilersManagment.scss";
import ServiceBox from "../../../components/ServiceBox/ServiceBox";
// import icon1 from "../../../public/assets/icons/Broilers Managment Programs.webp";
// import icon2 from "../../../public/assets/icons/Cumulative Egg Production For Brown Laying Hens Program.webp"; // not
// import icon3 from "../../../public/assets/icons/Feed Consumption Rate Program.webp";
// import icon4 from "../../../public/assets/icons/Water Consumption Rate Program.webp";
// import icon5 from "../../../public/assets/icons/Total Feed Consumption For Brown Pullets Program.webp";
// import icon6 from "../../../public/assets/icons/Feed Convertion Ratio FCR Program.webp";
// import icon7 from "../../../public/assets/icons/Normal Body Weight Program.webp";
// import icon8 from "../../../public/assets/icons/Livability Percentage Program.webp";
// import icon9 from "../../../public/assets/icons/Production Guide For White Laying Hens.webp";
// import icon10 from "../../../public/assets/icons/European production Efficiency Factor Program.webp";
// import icon11 from "../../../public/assets/icons/Lighting Rate Program.webp";
// import icon12 from "../../../public/assets/icons/Cumulative Egg Production For Brown Laying Hens Program.webp"; // not
// import icon13 from "../../../public/assets/icons/Amount Of Water Needed For Vaccination Program.webp";
// import icon14 from "../../../public/assets/icons/Normal Mortality Rate Program.webp";
import ar from "../../../lang/ar/ar.json";
import en from "../../../lang/en/en.json";
import fr from "../../../lang/fr/fr.json";
import { useSelector } from "react-redux";
import MainFourServisesLayout from "../../../components/MainFourServisesLayout/MainFourServisesLayout";

function BroilersManagment() {
	// not yet 1 + 2 + 12
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;
	const broilers = [
		{
			id: 1,
			title: lang.Broilers_Managment,
			description: lang.Broilers_Managment,
			imgSrc: "/assets/icons/Broilers Managment Programs.webp",
			link: "broilers-management",
			isUnderConstruction: true,
		},
		{
			id: 2,
			title: lang.Housing_Desnity,
			description: lang.Housing_Desnity,
			imgSrc:
				"/assets/icons/Cumulative Egg Production For Brown Laying Hens Program.webp",
			link: "",
			isUnderConstruction: true,
		},
		{
			id: 3,
			title: lang.Feed_Consumption_Rate,
			description: lang.Feed_Consumption_Rate,
			imgSrc:
				"/assets/icons/Cumulative Egg Production For Brown Laying Hens Program.webp",
			link: "feed-consumption-program",
		},
		{
			id: 4,
			title: lang.Water_Consumption_Rate,
			description: lang.Water_Consumption_Rate,
			imgSrc: "/assets/icons/Water Consumption Rate Program.webp",
			link: "water-consumption-rate-program",
		},
		{
			id: 5,
			title: lang.Total_Feed_Consumption_Rate,
			description: lang.Total_Feed_Consumption_Rate,
			imgSrc:
				"/assets/icons/Total Feed Consumption For Brown Pullets Program.webp",
			link: "total-feed-consumptionRate-program",
		},
		{
			id: 6,
			title: lang.Feed_Convertion_Ratio_FCR,
			description: lang.Feed_Convertion_Ratio_FCR,
			imgSrc: "/assets/icons/Feed Convertion Ratio FCR Program.webp",
			link: "feed-convertion-ratio-FCR-program",
		},
		{
			id: 7,
			title: lang.Normal_Body_Weight,
			description: lang.Normal_Body_Weight,
			imgSrc: "/assets/icons/Normal Body Weight Program.webp",
			link: "normal-body-weight-program",
		},
		{
			id: 8,
			title: lang.Livability_Percentage,
			description: lang.Livability_Percentage,
			imgSrc: "/assets/icons/Livability Percentage Program.webp",
			link: "livability-percentage-program",
		},
		{
			id: 9,
			title: lang.Production_Guide,
			description: lang.Production_Guide,
			imgSrc: "/assets/icons/Production Guide For White Laying Hens.webp",
			link: "production-guide-program",
		},
		{
			id: 10,
			title: lang.European_production_Efficiency_Factor,
			description: lang.European_production_Efficiency_Factor,
			imgSrc:
				"/assets/icons/European production Efficiency Factor Program.webp",
			link: "european-production-efficiency-factor-program",
		},
		{
			id: 11,
			title: lang.Lighting_Rate,
			description: lang.Lighting_Rate,
			imgSrc: "/assets/icons/Lighting Rate Program.webp",
			link: "lighting-rate-program",
		},
		{
			id: 12,
			title: lang.Ventilation_Rate,
			description: lang.Ventilation_Rate,
			imgSrc:
				"/assets/icons/Cumulative Egg Production For Brown Laying Hens Program.webp",
			link: "Ventilation Rate Program",
		},
		{
			id: 13,
			title: lang.Amount_Of_Water_Needed_For_Vaccination,
			description: lang.Amount_Of_Water_Needed_For_Vaccination,
			imgSrc:
				"/assets/icons/Amount Of Water Needed For Vaccination Program.webp",
			link: "amount-of-water-needed-for-vaccination-program",
		},
		{
			id: 14,
			title: lang.Normal_Mortality_Rate_Program,
			description: lang.Normal_Mortality_Rate_Program,
			imgSrc: "/assets/icons/Normal Mortality Rate Program.webp",
			link: "normal-mortality-rate-program",
		},
	];

	return (
		<MainFourServisesLayout
			serviceData={broilers}
			title={lang.Broilers_Managment}
			ignoredServicesId={1}
		/>
	);
}

export default BroilersManagment;
