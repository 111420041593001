import React, { useState } from "react";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
// import icon from "../../../../../public/assets/icons/Cumulative Egg Production For Brown Laying Hens Program.webp";
import InputField from "../../../../../components/Calculations/InputField";
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";

const CumulativeEggProductionForBrownLayingHensProgram = () => {
	const [age, setAge] = useState("");
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [result, setResult] = useState("");
	const [week, setWeek] = useState("");

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	// Example cumulative egg data (replace with your actual data)
	const cumEggData = [
		,
		20,
		40,
		60,
		90,
		120,
		150,
		200,
		270,
		360,
		470,
		600,
		750,
		920,
		1120,
		1350,
		1610,
		1900,
		2230,
		2590,
		2980,
		3400,
		3850,
		4330,
		4840,
		5380,
		5950,
		6550,
		7180,
		7840,
		8530,
		9250,
		10000,
		10780,
		11590,
		12410,
		13240,
		14080,
		14930,
		15790,
		16660,
		17540,
		18430,
		19330,
		20240,
		21160,
		22090,
		23030,
		23970,
		24910,
		25850,
		26790,
		27730,
		28670,
		29610,
		30560,
		31510,
		32460,
		33410,
		34360,
		35310,
		36260,
		37210,
		38160,
		39110,
		40060,
		41010,
		41960,
		42910,
		43860,
		44810,
		45760,
		46710,
		47660,
		48610,
		49560,
		50510,
		51460,
		52410,
		53360,
		54310,
		55260,
		56210,
		57160,
		58100,
		59040,
		59980,
		60920,
		61860,
		62800,
		63740,
		64680,
		65620,
		66560,
		67500,
		68440,
		69380,
		70320,
		71250,
		72180,
		73110,
		74040,
		74970,
		75900,
		76830,
		77760,
		78690,
		79620,
		80550,
		81480,
		82410,
		83340,
		84270,
		85200,
		86130,
		87060,
		87990,
		88920,
		89850,
		90780,
		91710,
		92640,
		93570,
		94500,
		95430,
		96360,
		97280,
		98200,
		99120,
		100040,
		100960,
		101880,
		102800,
		103720,
		104640,
		105560,
		106480,
		107400,
		108320,
		109240,
		110160,
		111080,
		112000,
		112920,
		113840,
		114760,
		115680,
		116600,
		117520,
		118440,
		119360,
		120280,
		121200,
		122120,
		123040,
		123960,
		124880,
		125800,
		126720,
		127640,
		128560,
		129470,
		130380,
		131290,
		132200,
		133110,
		134020,
		134930,
		135840,
		136750,
		137660,
		138570,
		139480,
		140390,
		141300,
		142210,
		143120,
		144030,
		144940,
		145850,
		146760,
		147670,
		148570,
		149470,
		150370,
		151270,
		152170,
		153070,
		153970,
		154870,
		155770,
		156670,
		157570,
		158470,
		159370,
		160270,
		161170,
		162070,
		162970,
		163870,
		164770,
		165670,
		166570,
		167460,
		168350,
		169240,
		170130,
		171020,
		171910,
		172800,
		173690,
		174580,
		175470,
		176360,
		177250,
		178140,
		179030,
		179910,
		180790,
		181670,
		182550,
		183430,
		184310,
		185190,
		186070,
		186950,
		187830,
		188710,
		189590,
		190470,
		191350,
		192220,
		193090,
		193960,
		194830,
		195700,
		196570,
		197440,
		198310,
		199180,
		200050,
		200920,
		201790,
		202660,
		203530,
		204390,
		205250,
		206110,
		206970,
		207830,
		208690,
		209550,
		210410,
		211270,
		212130,
		212990,
		213850,
		214710,
		215570,
		216420,
		217270,
		218120,
		218970,
		219820,
		220670,
		221520,
		222370,
		223220,
		224070,
		224920,
		225770,
		226620,
		227470,
		228310,
		229150,
		229990,
		230830,
		231670,
		232510,
		233350,
		234190,
		235030,
		235870,
		236710,
		237550,
		238390,
		239230,
		240060,
		240890,
		241720,
		242550,
		243380,
		244210,
		245040,
		245870,
		246700,
		247530,
		248360,
		249190,
		250020,
		250850,
		251670,
		252490,
		253310,
		254130,
		254950,
		255770,
		256590,
		257410,
		258230,
		259050,
		259870,
		260690,
		261510,
		262330,
		263140,
		263950,
		264760,
		265570,
		266380,
		267190,
		268000,
		268810,
		269620,
		270430,
		271240,
		272050,
		272860,
		273670,
		274470,
		275270,
		276070,
		276870,
		277670,
		278470,
		279270,
		280070,
		280870,
		281670,
		282470,
		283270,
		284070,
		284870,
		285660,
		286450,
		287240,
		288030,
		288820,
		289610,
		290400,
		291190,
		291980,
		292770,
		293560,
		294350,
		295140,
		295930,
		296710,
		297490,
		298270,
		299050,
		299830,
		300610,
		301390,
		302170,
		302950,
		303730,
		304510,
		305290,
		306070,
		306850,
		307620,
		308390,
		309160,
		309930,
		310700,
		311470,
		312240,
		313010,
		313780,
		314550,
		315320,
		316090,
		316860,
		317630,
		318390,
		319150,
		319910,
		320670,
		321430,
		322190,
		322950,
		323710,
		324470,
		325230,
		325990,
		326750,
		327510,
		328270,
		329030,
		329790,
		330550,
		331310,
		332070,
		332830,
		333590,
		334340,
		335090,
		335840,
		336590,
		337340,
		338090,
		338840,
		339590,
		340340,
		341090,
		341840,
		342590,
		343340,
		344090,
		344830,
		345570,
		346310,
		347050,
		347790,
		348530,
		349270,
		350010,
		350750,
		351490,
		352230,
		352970,
		353710,
		354450,
		355180,
		355910,
		356640,
		357370,
		358100,
		358830,
		359560,
		360290,
		361020,
		361750,
		362480,
		363210,
		363940,
		364670,
		365390,
		366110,
		366830,
		367550,
		368270,
		368990,
		369710,
		370430,
		371150,
		371870,
		372590,
		373310,
		374030,
		374750,
		375460,
		376170,
		376880,
		377590,
		378300,
		379010,
		379720,
		380420,
		381120,
		381820,
		382520,
		383220,
		383920,
		384620,
		385320,
		386020,
		386720,
		387420,
		388120,
		388820,
		389520,
		390210,
		390900,
		391590,
		392280,
		392970,
		393660,
		394350,
		395040,
		395730,
		396420,
		397110,
		397800,
		398490,
		399180,
		399860,
		400540,
		401220,
		401900,
		402580,
		403260,
		403940,
		404620,
		405300,
		405980,
		406660,
		407340,
		408020,
		408700,
		409370,
		410040,
		410710,
		411380,
		412050,
		412720,
		413390,
		414060,
		414730,
		415400,
		416070,
		416740,
		417410,
		418080,
		418740,
		419400,
		420060,
		420720,
		421380,
		422040,
		422700,
		423360,
		424020,
		424680,
		425340,
		426000,
		426660,
		427320,
	];
	const calculateBtnFunction = () => {
		if (age < 120 || age > 658) {
			alert(lang.MinimumAgeMaximumAge);
			return;
		}

		const weeks = Math.ceil(age / 7);
		setWeek(weeks);

		const index = age - 120;

		// Ensure index is within bounds
		if (index >= 0 && index < cumEggData.length) {
			const eggCount = (cumEggData[index] * numberOfBirds) / 1000;
			const trayCount = eggCount / 30;

			setResult(
				`${newLang.CumulativeEggProduction} = ${eggCount.toFixed(2)} ${
					newLang.Egg
				}.\n\n${newLang.CumulativeEggProduction} = ${trayCount.toFixed(2)} ${
					newLang.tray
				} (${newLang.Egg}).`
			);
		} else {
			setResult(newLang.Ageoutofbounds);
		}
	};

	useEnterKeyPress(calculateBtnFunction);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.brownLayingHensManagmentPrograms
						.CumulativeEggProductionForBrownLayingHensProgram
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${newLang.numberOfHens}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={newLang.enterNumberOfHens}
					/>
					<InputField
						label={`${lang.age}:`}
						value={age}
						onChange={(e) => setAge(e.target.value)}
						placeholder={lang.enterAgeOfBirds}
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateBtnFunction}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={
							"/assets/icons/Cumulative Egg Production For Brown Laying Hens Program.webp"
						}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
		</section>
	);
};

export default CumulativeEggProductionForBrownLayingHensProgram;
