import React, { useState } from "react";
import InputField from "../../../../../components/Calculations/InputField";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
// import icon from "../../../../../public/assets/icons/Egg Percentage For White Laying Hens Program.webp"; // Updated icon
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";

function EggPercentageForWhiteLayingHensProgram() {
	const [age, setAge] = useState("");
	const [numberOfHens, setNumberOfHens] = useState("");
	const [result, setResult] = useState("");

	const eggPercent = [
		0.01, 0.01, 0.02, 0.02, 0.03, 0.03, 0.03, 0.04, 0.05, 0.06, 0.07, 0.09, 0.1,
		0.12, 0.14, 0.17, 0.2, 0.23, 0.26, 0.28, 0.3, 0.33, 0.36, 0.39, 0.42, 0.45,
		0.48, 0.53, 0.57, 0.62, 0.66, 0.69, 0.72, 0.75, 0.78, 0.8, 0.82, 0.83, 0.84,
		0.85, 0.86, 0.87, 0.88, 0.89, 0.9, 0.91, 0.91, 0.92, 0.92, 0.93, 0.93, 0.94,
		0.94, 0.94, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95,
		0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95,
		0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95,
		0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95,
		0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95,
		0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.94, 0.94, 0.94, 0.94, 0.94,
		0.94, 0.94, 0.94, 0.94, 0.94, 0.94, 0.94, 0.94, 0.94, 0.94, 0.93, 0.93,
		0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93,
		0.93, 0.92, 0.92, 0.92, 0.92, 0.92, 0.92, 0.92, 0.92, 0.92, 0.92, 0.92,
		0.92, 0.92, 0.92, 0.92, 0.92, 0.92, 0.92, 0.92, 0.92, 0.92, 0.92, 0.91,
		0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91,
		0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.9, 0.9, 0.9, 0.9, 0.9,
		0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9,
		0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 0.89, 0.89, 0.89, 0.89, 0.89, 0.89,
		0.89, 0.89, 0.89, 0.89, 0.89, 0.89, 0.89, 0.89, 0.89, 0.89, 0.89, 0.89,
		0.89, 0.89, 0.89, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88,
		0.88, 0.88, 0.88, 0.88, 0.88, 0.87, 0.87, 0.87, 0.87, 0.87, 0.87, 0.87,
		0.87, 0.87, 0.87, 0.87, 0.87, 0.87, 0.87, 0.87, 0.87, 0.87, 0.87, 0.87,
		0.87, 0.87, 0.86, 0.86, 0.86, 0.86, 0.86, 0.86, 0.86, 0.86, 0.86, 0.86,
		0.86, 0.86, 0.86, 0.86, 0.86, 0.86, 0.86, 0.86, 0.86, 0.86, 0.86, 0.85,
		0.85, 0.85, 0.85, 0.85, 0.85, 0.85, 0.85, 0.85, 0.85, 0.85, 0.85, 0.85,
		0.85, 0.85, 0.85, 0.85, 0.85, 0.85, 0.85, 0.85, 0.84, 0.84, 0.84, 0.84,
		0.84, 0.84, 0.84, 0.84, 0.84, 0.84, 0.84, 0.84, 0.84, 0.83, 0.83, 0.83,
		0.83, 0.83, 0.83, 0.83, 0.83, 0.83, 0.83, 0.83, 0.83, 0.83, 0.83, 0.83,
		0.83, 0.83, 0.83, 0.83, 0.83, 0.83, 0.83, 0.82, 0.82, 0.82, 0.82, 0.82,
		0.82, 0.82, 0.82, 0.82, 0.82, 0.82, 0.82, 0.82, 0.82, 0.82, 0.82, 0.82,
		0.82, 0.82, 0.82, 0.82, 0.81, 0.81, 0.81, 0.81, 0.81, 0.81, 0.81, 0.81,
		0.81, 0.81, 0.81, 0.81, 0.81, 0.81, 0.81, 0.81, 0.81, 0.81, 0.81, 0.81,
		0.81, 0.81, 0.81, 0.81, 0.81, 0.81, 0.81, 0.81, 0.8, 0.8, 0.8, 0.8, 0.8,
		0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.79, 0.79, 0.79, 0.79, 0.79,
		0.79, 0.79, 0.79, 0.79, 0.79, 0.79, 0.79, 0.79, 0.79, 0.78, 0.78, 0.78,
		0.78, 0.78, 0.78, 0.78, 0.78, 0.78, 0.78, 0.78, 0.78, 0.78, 0.78, 0.77,
		0.77, 0.77, 0.77, 0.77, 0.77, 0.77, 0.76, 0.76, 0.76, 0.76, 0.76, 0.76,
		0.76, 0.76, 0.76, 0.76, 0.76, 0.76, 0.76, 0.76, 0.75, 0.75, 0.75, 0.75,
		0.75, 0.75, 0.75, 0.74, 0.74, 0.74, 0.74, 0.74, 0.74, 0.74, 0.74, 0.74,
		0.74, 0.74, 0.74, 0.74, 0.74, 0.73, 0.73, 0.73, 0.73, 0.73, 0.73, 0.73,
		0.72, 0.72, 0.72, 0.72, 0.72, 0.72, 0.72, 0.72, 0.72, 0.72, 0.72, 0.72,
		0.72, 0.72, 0.71, 0.71, 0.71, 0.71, 0.71, 0.71, 0.71, 0.71, 0.71, 0.71,
		0.71, 0.71, 0.71, 0.71, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7,
		0.7, 0.7, 0.7, 0.7, 0.69, 0.69, 0.69, 0.69,
	];

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const calculateResults = () => {
		const ageNum = parseFloat(age);
		const numberHens = parseFloat(numberOfHens);

		if (
			!isNaN(ageNum) &&
			!isNaN(numberHens) &&
			ageNum >= 120 &&
			ageNum <= 658
		) {
			const index = ageNum - 120;
			if (index >= 0 && index < eggPercent.length) {
				const eggPercentage = eggPercent[index];
				const normalEggPercentage = eggPercentage * 100;
				const normalEggProduction = eggPercentage * numberHens;
				const trayCount = (normalEggProduction / 30).toFixed(2);

				setResult(
					`${newLang.NormalEggPercentage} = ${normalEggPercentage} %.\n\n${newLang.NormalEggProduction} = ${normalEggProduction} Eggs.\n\n${newLang.TrayCount} = ${trayCount} tray (30 Eggs).`
				);
			} else {
				setResult(lang.AgeOutOfRangeMessage);
			}
		} else {
			setResult(lang.InvalidInputMessage);
		}
	};

	useEnterKeyPress(calculateResults);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.whiteLayingHensManagmentPrograms
						.EggPercentageForWhiteLayingHensProgram
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${newLang.NumberOfHens}:`}
						value={numberOfHens}
						onChange={(e) => setNumberOfHens(e.target.value)}
						placeholder={newLang.EnterNumberOfHens}
					/>
					<InputField
						label={`${newLang.Age}:`}
						value={age}
						onChange={(e) => setAge(e.target.value)}
						placeholder={newLang.EnterAge}
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateResults}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={
							"/assets/icons/Egg Percentage For White Laying Hens Program.webp"
						}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
		</section>
	);
}

export default EggPercentageForWhiteLayingHensProgram;
