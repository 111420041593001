import React, { useState } from "react";
import InputField from "../../../../../components/Calculations/InputField";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
// import icon from "../../../../../public/assets/icons//Total Feed Consumption For Brown Pullets Program.webp"; // Updated icon
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";

function TotalFeedConsumptionForBrownPulletsProgram() {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [result, setResult] = useState("");
	const [weeks, setWeeks] = useState("");

	// Cumulative feed intake array (equivalent to PHP $CFI array)
	const CFI = [
		0, 1, 2, 3, 4, 5, 7, 9, 10, 12, 14, 16, 18, 19, 21, 23, 25, 27, 29, 31, 33,
		35, 37, 38, 40, 42, 44, 46, 49, 51, 53, 55, 57, 60, 62, 65, 67, 70, 72, 75,
		77, 80, 83, 85, 88, 91, 94, 96, 99, 102, 105, 108, 110, 113, 116, 119, 122,
		125, 128, 131, 134, 137, 139, 142, 145, 148, 151, 155, 158, 161, 164, 167,
		170, 173, 176, 180, 183, 186, 189, 193, 196, 199, 203, 206, 209, 213, 216,
		219, 223, 226, 230, 233, 237, 240, 244, 248, 251, 255, 259, 262, 266, 270,
		274, 277, 281, 285, 289, 293, 297, 301, 305, 309, 313, 317, 321, 325, 330,
		334, 338, 342, 347, 351, 356, 360, 364, 369, 369,
	];

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const calculateResults = () => {
		const numBirds = parseFloat(numberOfBirds);
		const age = parseFloat(ageOfBirds);

		if (!isNaN(numBirds) && !isNaN(age) && numBirds >= 0 && age >= 0) {
			// Check if the age exceeds the array limit
			if (age > CFI.length - 1) {
				alert(newLang.BirdAgeExceedsLimit); // Assuming BirdAgeExceedsLimit is a localized message
				return;
			}

			// Calculate the week based on the bird's age
			const weeksCalculated = Math.ceil(age / 7);
			setWeeks(weeksCalculated);

			// Cumulative feed intake logic based on age and number of birds
			const feedIntake = (CFI[age] * numBirds) / 1000;
			const feedIntakeInTons = feedIntake / 1000;

			// Update result with feed intake in kg and tons
			setResult(
				`${newLang.NormalCumulativeFeedIntake} = ${feedIntake} ${newLang.Kg} = ${feedIntakeInTons} ${newLang.ton}`
			);
		} else {
			setResult(lang.InvalidInputMessage);
		}
	};

	useEnterKeyPress(calculateResults);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.brownPulletsManagmentPrograms
						.TotalFeedConsumptionForBrownPulletsProgram
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.EnterAgeOfBirds}
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateResults}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={
							"/assets/icons//Total Feed Consumption For Brown Pullets Program.webp"
						}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
		</section>
	);
}

export default TotalFeedConsumptionForBrownPulletsProgram;
