import React from "react";
import "./DrugInteractions.scss";

// import icon1 from "../../../public/assets/icons/Broilers Managment Programs.webp";
// import icon2 from "../../../public/assets/icons/Cumulative Egg Production For Brown Laying Hens Program.webp"; // not
import ar from "../../../lang/ar/ar.json";
import en from "../../../lang/en/en.json";
import fr from "../../../lang/fr/fr.json";
import { useSelector } from "react-redux";

import MainFourServisesLayout from "../../../components/MainFourServisesLayout/MainFourServisesLayout";

function DrugInteractions() {
	// not yet 1 + 2 + 12
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.drugInteractions
			: language === "ar"
			? ar.services.drugInteractions
			: fr.services.drugInteractions;
	const drugInteractionsServices = [
		{
			id: 1,
			title: lang.DrugInteractionsProgram,
			description: lang.DrugInteractionsProgram,
			imgSrc: "/assets/icons/Broilers Managment Programs.webp",
			link: "drug-interactions-program",
		},
		{
			id: 2,
			title: lang.DrugDosePrograms,
			description: lang.DrugDosePrograms,
			imgSrc:
				"/assets/icons/Cumulative Egg Production For Brown Laying Hens Program.webp",
			link: "drug-dose-programs",
		},
	];

	return (
		<MainFourServisesLayout
			serviceData={drugInteractionsServices}
			title={"drug-interactions-program"}
			ignoredServicesId={3}
		/>
	);
}

export default DrugInteractions;
