import React, { useState } from "react";
import InputField from "../../../../../components/Calculations/InputField";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
// import icon from "../../../../../public/assets/icons/Normal Body Weight Program.webp"; // Update icon path
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";

// Define the body weight array in JavaScript
const ST_B_W = [
	40, 42, 44, 46, 50, 55, 60, 65, 70, 77, 84, 91, 100, 110, 120, 128, 136, 145,
	155, 165, 175, 185, 196, 206, 217, 228, 239, 250, 261, 271, 282, 293, 304,
	315, 326, 337, 348, 359, 370, 380, 390, 400, 410, 422, 435, 449, 462, 475,
	488, 502, 516, 530, 544, 558, 572, 586, 600, 614, 628, 642, 656, 670, 684,
	700, 712, 724, 736, 748, 760, 772, 785, 797, 809, 821, 833, 845, 857, 870,
	882, 894, 906, 918, 930, 942, 955, 967, 978, 989, 1000, 1010, 1020, 1029,
	1038, 1047, 1056, 1065, 1074, 1083, 1092, 1101, 1110, 1119, 1128, 1137, 1146,
	1153, 1159, 1165, 1171, 1177, 1183, 1189, 1195, 1201, 1207, 1213, 1219, 1225,
	1231, 1237, 1241, 1245, 1249, 1253, 1257, 1261, 1265,
];

const NormalBodyWeightForWhitePulletsProgram = () => {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [weeks, setWeeks] = useState("");
	const [result, setResult] = useState("");

	const language = useSelector((state) => state?.lang?.language);
	const lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const calculateResults = () => {
		const age = parseInt(ageOfBirds, 10);

		if (!isNaN(age) && age >= 0) {
			const weeksCalculated = Math.ceil(age / 7);
			setWeeks(weeksCalculated);

			// Get the standard body weight for the given age
			const normalBodyWeight = ST_B_W[age] || "N/A"; // Handle case if age is out of range

			setResult(
				`Normal body weight = ${normalBodyWeight} gram. \n\n ${weeksCalculated} ${newLang.weeks}`
			);
		} else {
			setResult(lang.InvalidInputMessage);
		}
	};

	useEnterKeyPress(calculateResults);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.whitePulletsManagmentPrograms
						.NormalBodyWeightForWhitePulletsProgram
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.EnterAgeOfBirds}
					/>
					{/* <InputField
						label={`${lang.WeeksCalculated}:`}
						value={weeks}
						placeholder={lang.WeeksCalculatedPlaceholder}
						readOnly
					/> */}
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateResults}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={"/assets/icons/Normal Body Weight Program.webp"}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
		</section>
	);
};

export default NormalBodyWeightForWhitePulletsProgram;
