import React from "react";
import "./Emergency.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
// import EmergencyImg from "../../public/assets/emergency.png";
import { Link } from "react-router-dom";
import ar from "../../lang/ar/ar.json";
import en from "../../lang/en/en.json";
import fr from "../../lang/fr/fr.json";
import { useSelector } from "react-redux";

const Emergency = () => {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en" ? en.hero : language === "ar" ? ar.hero : fr.hero;
	return (
		<section
			className='emergency-section'
			data-aos='fade-up'
			data-aos-duration='2000'
		>
			<div className='container-fluid'>
				<div className='row align-items-center'>
					<div className='col-lg-6 col-md-6'>
						<div className='emergency-img'>
							<img
								src={"/imgs/chicken.jpg"}
								alt='Emergency'
							/>
						</div>
					</div>
					<div className='col-lg-6 col-md-6'>
						<div className='emergency-text'>
							<SectionTitle
								subTitle={lang.diagnostic}
								title={lang.diagnostic_title}
								description={lang.diagnostic_description}
							/>

							<div className='theme-btn'>
								<Link to='/'>{lang.book_now}</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Emergency;
