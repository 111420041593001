import React, { useState } from "react";
import useEnterKeyPress from "../../../../utils/EnterCalculateBtn";
import InputField from "../../../../components/Calculations/InputField";
import { useSelector } from "react-redux";
import ar from "../../../../lang/ar/ar.json";
import en from "../../../../lang/en/en.json";
import fr from "../../../../lang/fr/fr.json";
// import icon from "../../../../public/assets/icons/Amount Of Water Needed For Vaccination Program.webp"; // Use appropriate icon

const AmountOfWaterNeededForVaccinationProgram = () => {
	// State variables for input values and result
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [result, setResult] = useState("");

	// Function to handle the calculation
	const calculateResults = () => {
		const age = parseFloat(ageOfBirds);
		if (!isNaN(age)) {
			const res1 = age * 2.1;
			setResult(`${lang.amountOfWater} ${res1.toFixed(1)} ${lang.litre}`);
		} else {
			setResult(lang.invalidAge);
		}
	};

	useEnterKeyPress(calculateResults);

	// Fetch the selected language from the Redux store
	const language = useSelector((state) => state?.lang?.language);
	let lang;
	switch (language) {
		case "en":
			lang = en.services.broilers;
			break;
		case "ar":
			lang = ar.services.broilers;
			break;
		case "fr":
			lang = fr.services.broilers;
			break;
		default:
			lang = en.services.broilers; // Default to English
	}

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>{lang.heading}</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.numberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.Enterageofbirds}
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateResults}
							className='btn appointment-btn'
						>
							{lang.calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={
							"/assets/icons/Amount Of Water Needed For Vaccination Program.webp"
						}
						alt={lang.iconAltText}
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
		</section>
	);
};

export default AmountOfWaterNeededForVaccinationProgram;
