import React from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import ServiceBox from "../ServiceBox/ServiceBox";
import { useSelector } from "react-redux";
import SuggestedServices from "../SuggestedServices/SuggestedServices";
import { useServicesData } from "../../sections/Services/AllServices";
function MainFourServisesLayout({
	title,
	serviceData,
	ignoredServicesId,
	isShowSuggestedServices = true,
}) {
	const language = useSelector((state) => state?.lang?.language);

	const servicesData = useServicesData();
	const filteredServicesData = servicesData.filter(
		(service) => service.id !== ignoredServicesId
	);

	return (
		<section
			className='main-Broilers container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<div className='row'>
				<h3
					className='services-sub-title'
					style={{ padding: "15px 0 35px", fontWeight: "600" }}
				>
					{title}
				</h3>
			</div>
			{serviceData &&
				serviceData.map((item) => (
					<ServiceBox
						number={item.id}
						title={item.title}
						description={item.description}
						imgSrc={item.imgSrc}
						link={`${item.link}`}
						isUnderConstruction={item.isUnderConstruction}
					/>
				))}

			{isShowSuggestedServices && (
				<div style={{ margin: "20px auto" }}>
					<SuggestedServices servicesData={filteredServicesData} />
				</div>
			)}
		</section>
	);
}

export default MainFourServisesLayout;
