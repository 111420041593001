import React, { useState } from "react";
import "./ServiceBox.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ar from "../../lang/ar/ar.json";
import en from "../../lang/en/en.json";
import fr from "../../lang/fr/fr.json";
import { showAlert } from "../../utils/showAlert";
import AlertModal from "../../shared/AlertModal";

function ServiceBox({
	imgSrc,
	title,
	description,
	link,
	number,
	isUnderConstruction,
}) {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	const [isModalOpen, setModalOpen] = useState(false);
	const [currentFeature, setCurrentFeature] = useState("");

	const handleAction = (feature) => {
		setCurrentFeature(feature);
		setModalOpen(true); // Open the modal
	};

	const handleClose = () => {
		setModalOpen(false); // Close the modal
	};
	return (
		<div className='service-item'>
			<div>
				<span>{number}</span>
				{isUnderConstruction ? (
					<Link
						to={""}
						onClick={() => handleAction(title)}
					>
						<img
							src={imgSrc}
							alt={title}
							width={80}
							height={80}
						/>
					</Link>
				) : (
					<Link to={link}>
						<img
							src={imgSrc}
							alt={title}
							width={80}
							height={80}
						/>
					</Link>
				)}
			</div>

			{isUnderConstruction ? (
				<Link
					className='title-link'
					to={""}
					onClick={() => handleAction(title)}
				>
					{title}
				</Link>
			) : (
				<Link
					className='title-link'
					to={link}
				>
					{title}
				</Link>
			)}

			{/* <p>{description}</p> */}

			{isUnderConstruction ? (
				<div className='theme-btn'>
					<Link
						to={""}
						onClick={() => handleAction(title)}
					>
						{lang.try_it_now}
					</Link>
				</div>
			) : (
				<div className='theme-btn'>
					<Link to={link}>{lang.try_it_now}</Link>
				</div>
			)}

			<AlertModal
				variable={currentFeature}
				isOpen={isModalOpen}
				onClose={handleClose}
			/>
		</div>
	);
}

export default ServiceBox;
