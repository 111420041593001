import React from "react";
import "./LayersManagment.scss";
import ServiceBox from "../../../components/ServiceBox/ServiceBox";

import ar from "../../../lang/ar/ar.json";
import en from "../../../lang/en/en.json";
import fr from "../../../lang/fr/fr.json";
import { useSelector } from "react-redux";
import SuggestedServices from "../../../components/SuggestedServices/SuggestedServices";
import { useServicesData } from "../../../sections/Services/AllServices";
import WhitePulletsManagmentPrograms from "./WhitePulletsManagmentPrograms/WhitePulletsManagmentPrograms";
import WhiteLayingHensManagmentPrograms from "./WhiteLayingHensManagmentPrograms/WhiteLayingHensManagmentPrograms";
import BrownLayingHensManagmentPrograms from "./BrownLayingHensManagmentPrograms/BrownLayingHensManagmentPrograms";
import BrownPulletsManagmentPrograms from "./BrownPulletsManagmentPrograms/BrownPulletsManagmentPrograms";

function LayersManagment() {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const layersManagmentServices = [
		{
			id: 1,
			title: lang.WhitePulletsManagmentPrograms,
			description: lang.WhitePulletsManagmentPrograms,
			imgSrc: "/assets/icons/NormalBodyWeightForWhitePulletsProgram.webp",
			link: "poultry-feed-analysis-program",
		},
		{
			id: 2,
			title: lang.WhiteLayingHensManagmentPrograms,
			description: lang.WhiteLayingHensManagmentPrograms,
			imgSrc: "/assets/icons/EggPercentageForWhiteLayingHensProgram.webp",
			link: "animal-feed-analysis-program",
		},
		{
			id: 3,
			title: lang.BrownPulletsManagmentPrograms,
			description: lang.BrownPulletsManagmentPrograms,
			imgSrc: "/assets/icons/NormalBodyWeightForBrownPulletsProgram.webp",
			link: "poultry-feed-analysis-program",
		},
		{
			id: 4,
			title: lang.BrownLayingHensManagmentPrograms,
			description: lang.BrownLayingHensManagmentPrograms,
			imgSrc: "/assets/icons/NormalBodyWeightForBrownLayingHensProgram.webp",
			link: "brown-layingHens-managment-programs",
		},
	];

	const servicesData = useServicesData();
	const filteredServicesData = servicesData.filter(
		(service) => service.id !== 2
	);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "55px",
				marginTop: "150px",
			}}
		>
			<WhitePulletsManagmentPrograms />
			<WhiteLayingHensManagmentPrograms />
			<BrownPulletsManagmentPrograms />
			<BrownLayingHensManagmentPrograms />
			<SuggestedServices servicesData={filteredServicesData} />
		</div>
	);
}

export default LayersManagment;
