import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

const AlertModal = ({ variable, isOpen, onClose }) => {
	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			aria-labelledby='alert-modal-title'
			aria-describedby='alert-modal-description'
		>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					borderRadius: 2,
					boxShadow: 24,
					p: 4,
					textAlign: "center",
				}}
			>
				<Typography
					id='alert-modal-title'
					variant='h6'
					component='h2'
				>
					{variable} is under construction
				</Typography>
				<Button
					variant='contained'
					color='primary'
					sx={{ mt: 2 }}
					onClick={onClose}
				>
					Close
				</Button>
			</Box>
		</Modal>
	);
};

export default AlertModal;
