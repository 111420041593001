import lang from "../../lang/en/en.json"; // Replace with dynamic language selection logic
console.log("laang", lang);
const ServicesData = [
	{
		icon: "/assets/service/1.png",
		title: "lang.services.dentures.title",
		description: "lang.services.dentures.description",
	},
	{
		icon: "/assets/service/2.png",
		title: "lang.services.implants.title",
		description: "lang.services.implants.description",
	},
	{
		icon: "/assets/service/3.png",
		title: "lang.services.whitening.title",
		description: "lang.services.whitening.description",
	},
	{
		icon: "/assets/service/4.png",
		title: "lang.services.rootCanal.title",
		description: "lang.services.rootCanal.description",
	},
];

export default ServicesData;
