import React from "react";
import "./Expert.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { BsFillCheckCircleFill } from "react-icons/bs";
// import expertImg from "../../public/assets/expert.png";
import ar from "../../lang/ar/ar.json";
import en from "../../lang/en/en.json";
import fr from "../../lang/fr/fr.json";
import { useSelector } from "react-redux";
const Expert = () => {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en" ? en.hero : language === "ar" ? ar.hero : fr.hero;
	return (
		<section
			className='expert-section'
			data-aos='fade-up'
			data-aos-duration='2000'
		>
			<div className='container'>
				<div className='row align-items-center'>
					<div className='col-lg-6 col-md-6'>
						<div className='expert-text'>
							<SectionTitle
								subTitle={lang.treatment}
								title={lang.treatment_title}
								description={lang.treatment_description}
							/>

							<ul>
								<li>
									<BsFillCheckCircleFill />
									{lang.treatment_benifit_one}
								</li>
								<li>
									<BsFillCheckCircleFill />
									{lang.treatment_benifit_two}
								</li>
								<li>
									<BsFillCheckCircleFill />
									{lang.treatment_benifit_three}
								</li>
							</ul>
						</div>
					</div>
					<div className='col-lg-6 col-md-6'>
						<div className='expert-img'>
							<img
								src={"/imgs/chicken2.jpg"}
								alt='expert'
								style={{ borderRadius: "20px" }}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Expert;
