import React from "react";
import "./FeedAnalysis.scss";
import ServiceBox from "../../../components/ServiceBox/ServiceBox";
// import icon1 from "../../../public/assets/icons/Feed Consumption Rate For Brown pullets Programs.webp"; //not
// import icon2 from "../../../public/assets/icons/Feed Analysis Program.webp"; // not

import ar from "../../../lang/ar/ar.json";
import en from "../../../lang/en/en.json";
import fr from "../../../lang/fr/fr.json";
import { useSelector } from "react-redux";
import SuggestedServices from "../../../components/SuggestedServices/SuggestedServices";
import { useServicesData } from "../../../sections/Services/AllServices";
import MainFourServisesLayout from "../../../components/MainFourServisesLayout/MainFourServisesLayout";

function FeedAnalysis() {
	// not yet 1 + 2 + 12
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.feedAnalysis
			: language === "ar"
			? ar.services.feedAnalysis
			: fr.services.feedAnalysis;
	const feedAnalysisServices = [
		{
			id: 1,
			title: lang.PoultryFeedAnalysisProgram,
			description: lang.PoultryFeedAnalysisProgram,
			imgSrc:
				"/assets/icons/Feed Consumption Rate For Brown pullets Programs.webp",
			link: "poultry-feed-analysis-program",
		},
		{
			id: 2,
			title: lang.AnimalFeedAnalysisProgram,
			description: lang.AnimalFeedAnalysisProgram,
			imgSrc: "/assets/icons/Feed Analysis Program.webp",
			link: "animal-feed-analysis-program",
		},
	];

	return (
		<MainFourServisesLayout
			serviceData={feedAnalysisServices}
			title={lang.feed_Analysis}
			ignoredServicesId={4}
		/>
	);
}

export default FeedAnalysis;
