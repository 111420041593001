import React from "react";
import "./IconList.scss";
import ThemeIcon from "../ThemeIcon/ThemeIcon";

const IconList = ({ icon, title, description, url }) => {
	return (
		<div className='col-lg-6 col-md-6'>
			<div className='icon-list'>
				<div className='icon-'>
					<a href={url}>
						<ThemeIcon icon={icon} />
					</a>
				</div>
				<div className='icon-list-text'>
					<a
						style={{ textDecoration: "none" }}
						href={url}
					>
						<h3>{title}</h3>
					</a>
					<a
						style={{ textDecoration: "none" }}
						href={url}
					>
						<p>{description}</p>
					</a>
				</div>
			</div>
		</div>
	);
};

export default IconList;
