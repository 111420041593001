import React from "react";
// import logo from "../../public/assets/logo.png";
import "./Footer.scss";
import {
	FaFacebookF,
	FaTwitter,
	FaInstagram,
	FaYoutube,
	FaLinkedinIn,
} from "react-icons/fa"; // Import YouTube and LinkedIn icons
import { Link } from "react-router-dom";
// import call from "../../public/assets/footer/calling.png";
// import time from "../../public/assets/footer/time.png";
// import location from "../../public/assets/footer/location.png";
import ar from "../../lang/ar/ar.json";
import en from "../../lang/en/en.json";
import fr from "../../lang/fr/fr.json";
import { useSelector } from "react-redux";
import ChangeLang from "../../utils/ChangeLang";

const Footer = () => {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en" ? en.footer : language === "ar" ? ar.footer : fr.footer;
	let navlang =
		language === "en" ? en.navbar : language === "ar" ? ar.navbar : fr.navbar;
	const footerMenu = [
		{
			name: navlang.about,
			link: "/about",
		},
		{
			name: navlang.services,
			link: "/services",
		},
		{
			name: navlang.vedios,
			link: "/videos",
		},
		// {
		// 	name: navlang.blogs,
		// 	link: "/blogs",
		// },
		// {
		// 	name: navlang.jobs,
		// 	link: "/jobs",
		// },
		// {
		// 	name: navlang.myAccount,
		// 	link: "/my-account",
		// },
		{
			name: navlang.contact,
			link: "/contact",
		},
	];

	const footerContacts = [
		{
			title: lang.Phone_Number,
			info: "+088 123 654 987",
			icon: "/assets/footer/calling.png",
		},
		{
			title: lang.Open_Hour,
			info: "09:00 AM - 18:00 PM",
			icon: "/assets/footer/time.png",
		},
		{
			title: lang.Clinic_Address,
			info: lang.addresses,
			icon: "/assets/footer/location.png",
		},
	];

	return (
		<footer
			dir={language === "ar" && "rtl"}
			className='pt-100 pb-70'
		>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-6 col-md-5'>
						<div className='footer-logo'>
							<img
								src={"/assets/logo.png"}
								alt='logo'
								width={100}
								height={100}
							/>
						</div>
						<p>{lang.phrase}</p>

						<div className='social-logo'>
							<p>{lang.follow_us}</p>
							<ul>
								<li>
									<a href='http://linkedin.com/in/vet-lab-496692ba'>
										<FaLinkedinIn />
									</a>
								</li>
								<li>
									<a href='https://youtube.com/channel/UCC3hleAGWoJudOUwJEblL0A'>
										<FaYoutube />
									</a>
								</li>
								{/* <li>
									<a href='/'>
										<FaInstagram />
									</a>
								</li> */}
							</ul>
							<div className='store-buttons'>
								<div
									className='button-container'
									style={{ display: "flex", gap: "6px" }}
								>
									{/* Apple Store Button */}
									<a
										href='https://apps.apple.com/eg/app/vetlab/id1516239486'
										target='_blank'
										rel='noopener noreferrer'
										className='store-button apple-store'
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											flexDirection: "column",
										}}
									>
										<img
											src='/assets/ios.png'
											alt='Apple Store'
											className='store-icon'
											style={{
												width: "100px",
												borderRadius: "8px",
											}}
										/>
									</a>

									{/* App Store Button */}
									<a
										href='https://play.google.com/store/apps/details?id=com.vetlab.app&hl=en&gl=US'
										target='_blank'
										rel='noopener noreferrer'
										className='store-button app-store'
									>
										<img
											src='/assets/png-clipart-google-play-mobile-app-logo-app-store-google-text-label-thumbnail.png'
											alt='App Store'
											className='store-icon'
											style={{ width: "100px", borderRadius: "8px" }}
										/>
									</a>
								</div>
							</div>
						</div>
					</div>

					<div className='col-lg-2 col-md-2'>
						<div className='footer-link'>
							<p>{lang.Quick_Links}</p>
							<ul>
								{footerMenu.map((singleMenu) => (
									<li>
										<Link to={singleMenu.link}>{singleMenu.name}</Link>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className='col-lg-4 col-md-5'>
						<div className='footer-contact'>
							<p>{lang.Contact_Information}</p>

							{footerContacts.map((footerContact) => {
								return (
									<div className='contact-list'>
										<div className='contact-icon'>
											<img
												src={footerContact.icon}
												alt='call'
											/>
										</div>
										<div className='contact-text'>
											<p>{footerContact.title}</p>
											<h5>{footerContact.info}</h5>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>

				<div className='copyright-area'>
					<div className='copy-text'>
						<p>&copy; {lang.rights}</p>
					</div>
					<div className='copy-links'>
						<ul>
							<li>
								<Link to='/'>{lang.Terms}</Link>
							</li>
							<li>
								<Link to='/'>{lang.Privacy_Policy}</Link>
							</li>
							{/* <li>
								<ChangeLang
									en={"en"}
									ar={"ar"}
									fr={"fr"}
								/>
							</li> */}
						</ul>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
