import React from "react";
import "./Symptoms.scss";
import IconList from "../../components/IconList/IconList";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
// import iconOne from "../../public/assets/symptoms/1.png";
// import iconTwo from "../../public/assets/symptoms/2.png";
// import iconThree from "../../public/assets/symptoms/3.png";
// import iconFour from "../../public/assets/symptoms/4.png";
// import iconFive from "../../public/assets/symptoms/5.png";
import { useServicesData } from "../Services/AllServices";

const Symptoms = ({ lang }) => {
	const servicesData = useServicesData();
	// const symptomsData = [
	// 	{
	// 		icon: "/assets/symptoms/1.png",
	// 		title: "Spontaneous pain",
	// 		description: "Spontaneous pain even when youre not eating or drinking",
	// 	},
	// 	{
	// 		icon: "/assets/symptoms/2.png",
	// 		title: "Discoloration of tooth",
	// 		description: "Darkening or discoloration of a tooth",
	// 	},
	// 	{
	// 		icon: "/assets/symptoms/3.png",
	// 		title: "Middle night pain",
	// 		description: "Pain that wakes you up in the middle of the night",
	// 	},
	// 	{
	// 		icon: "/assets/symptoms/4.png",
	// 		title: "Tenderness in gums",
	// 		description: "From floss bosses to sweet tooths, every mouth is welcome.",
	// 	},
	// 	{
	// 		icon: "/assets/symptoms/5.png",
	// 		title: "Extreme sensitivity",
	// 		description:
	// 			"Extreme sensitivity to cold or heat when your’e drink or eat",
	// 	},
	// ];

	const serviceLang = lang.services;

	return (
		<section
			className='symptoms-section section-common pt-100 pb-70'
			data-aos='fade-up'
			data-aos-duration='2000'
		>
			<div className='container'>
				<SectionTitle
					subTitle={serviceLang.services}
					title={serviceLang.title}
					description={serviceLang.phrase}
				/>

				<div className='row'>
					{servicesData.map((singleSymptoms) => (
						<IconList
							icon={singleSymptoms.icon}
							title={singleSymptoms.title}
							description={singleSymptoms.description}
							url={singleSymptoms.url}
						/>
					))}
				</div>
			</div>
		</section>
	);
};

export default Symptoms;
