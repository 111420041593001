import React from "react";
import MainFourServisesLayout from "../../../../components/MainFourServisesLayout/MainFourServisesLayout";
import ar from "../../../../lang/ar/ar.json";
import en from "../../../../lang/en/en.json";
import fr from "../../../../lang/fr/fr.json";
import { useSelector } from "react-redux";

// import icon1 from "../../../../public/assets/icons/Production Guide For Brown Laying Hens.webp";
// import icon2 from "../../../../public/assets/icons/Water Consumption Rate Program.webp"; // not
// import icon3 from "../../../../public/assets/icons/Feed Consumption Rate Program.webp"; // not
// import icon4 from "../../../../public/assets/icons/Total Feed Consumption For White Laying Hens Program.webp";
// import icon5 from "../../../../public/assets/icons/Normal Body Weight For Brown Laying Hens Program.webp";
// import icon6 from "../../../../public/assets/icons/Average Egg Weight For Brown Laying Hens Program.webp";
// import icon7 from "../../../../public/assets/icons/Egg Percentage For Brown Laying Hens Program.webp";
// import icon8 from "../../../../public/assets/icons/Cumulative Egg Production For Brown Laying Hens Program.webp";

function BrownLayingHensManagmentPrograms() {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.layersManagment.brownLayingHensManagmentPrograms
			: language === "ar"
			? ar.services.layersManagment.brownLayingHensManagmentPrograms
			: fr.services.layersManagment.brownLayingHensManagmentPrograms;

	const brownLayingHensManagmentProgramsServices = [
		{
			id: 1,
			title: lang.ProductionGuideForBrownLayingHensProgram,
			description: lang.ProductionGuideForBrownLayingHensProgram,
			imgSrc: "/assets/icons/Production Guide For Brown Laying Hens.webp",
			link: "production-guide-for-brown-laying-hens-program",
		},
		{
			id: 2,
			title: lang.WaterConsumptionRateForBrownLayingHensProgram,
			description: lang.WaterConsumptionRateForBrownLayingHensProgram,
			imgSrc: "/assets/icons/Water Consumption Rate Program.webp",
			link: "water-consumption-rate-for-brown-laying-hens-program",
		},
		{
			id: 3,
			title: lang.FeedConsumptionRateForBrownLayingHensProgram,
			description: lang.FeedConsumptionRateForBrownLayingHensProgram,
			imgSrc: "/assets/icons/Feed Consumption Rate Program.webp",
			link: "feed-consumption-rate-for-brown-laying-hens-program",
		},
		{
			id: 4,
			title: lang.TotalFeedConsumptionForWhiteLayingHensProgramTwo,
			description: lang.TotalFeedConsumptionForWhiteLayingHensProgramTwo,
			imgSrc:
				"/assets/icons/Total Feed Consumption For White Laying Hens Program.webp",
			link: "total-feed-consumption-for-white-laying-hens-program-two",
		},
		{
			id: 5,
			title: lang.NormalBodyWeightForBrownLayingHensProgram,
			description: lang.NormalBodyWeightForBrownLayingHensProgram,
			imgSrc:
				"/assets/icons/Normal Body Weight For Brown Laying Hens Program.webp",
			link: "normal-body-weight-for-brown-laying-hens-program",
		},
		{
			id: 6,
			title: lang.AverageEggWeightForBrownLayingHensProgram,
			description: lang.AverageEggWeightForBrownLayingHensProgram,
			imgSrc:
				"/assets/icons/Average Egg Weight For Brown Laying Hens Program.webp",
			link: "average-egg-weight-for-brown-laying-hens-program",
		},
		{
			id: 7,
			title: lang.EggPercentageForBrownLayingHensProgram,
			description: lang.EggPercentageForBrownLayingHensProgram,
			imgSrc: "/assets/icons/Egg Percentage For Brown Laying Hens Program.webp",
			link: "egg-percentage-for-brown-laying-hens-program",
		},
		{
			id: 8,
			title: lang.CumulativeEggProductionForBrownLayingHensProgram,
			description: lang.CumulativeEggProductionForBrownLayingHensProgram,
			imgSrc:
				"/assets/icons/Cumulative Egg Production For Brown Laying Hens Program.webp",
			link: "cumulative-egg-production-for-brown-laying-hens-program",
		},
	];

	return (
		<MainFourServisesLayout
			serviceData={brownLayingHensManagmentProgramsServices}
			title={lang.title}
			isShowSuggestedServices={false}
		/>
	);
}

export default BrownLayingHensManagmentPrograms;
