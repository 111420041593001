import React, { useState } from "react";
import InputField from "../../../../../components/Calculations/InputField"; // Adjust import path
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
// import icon from "../../../../../public/assets/icons/Average Egg Weight For Brown Laying Hens Program.webp"; // Update icon path
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn"; // Ensure this hook is implemented similarly

const eggWeights = [
	40, 40, 41, 42, 42, 43, 43, 44, 44, 45, 45, 45, 46, 46, 46.5, 46.5, 46.5,
	46.5, 46.5, 46.5, 47, 47.5, 48, 48.5, 49, 49.5, 50, 50.5, 51, 51.5, 52, 52.5,
	53, 53.5, 54, 54, 54, 54.5, 54.5, 54.5, 55, 55, 55, 55.5, 55.5, 56, 56, 56.5,
	56.5, 56.5, 57, 57.5, 58, 58, 58.5, 58.5, 58.5, 58.5, 59, 59, 59, 59.5, 59.5,
	59.5, 60, 60, 60, 60, 60, 60, 60, 60, 60.5, 60.5, 60.5, 60.5, 60.5, 61, 61,
	61.5, 61.5, 61.5, 62, 62, 62, 62, 62.1, 62.1, 62.2, 62.2, 62.2, 62.3, 62.3,
	62.3, 62.4, 62.4, 62.4, 62.4, 62.5, 62.5, 62.6, 62.6, 62.6, 62.7, 62.7, 62.8,
	62.8, 62.9, 62.9, 62.9, 63, 63, 63, 63.1, 63.1, 63.2, 63.2, 63.2, 63.3, 63.3,
	63.4, 63.4, 63.4, 63.5, 63.5, 63.5, 63.6, 63.6, 63.7, 63.7, 63.8, 63.8, 63.9,
	63.9, 63.9, 64, 64, 64, 64.1, 64.1, 64.1, 64.1, 64.1, 64.1, 64.2, 64.2, 64.2,
	64.2, 64.2, 64.2, 64.2, 64.2, 64.2, 64.2, 64.3, 64.3, 64.3, 64.3, 64.3, 64.3,
	64.3, 64.4, 64.4, 64.4, 64.4, 64.4, 64.4, 64.4, 64.5, 64.5, 64.5, 64.5, 64.5,
	64.5, 64.5, 64.5, 64.5, 64.5, 64.5, 64.5, 64.5, 64.5, 64.6, 64.6, 64.6, 64.6,
	64.6, 64.6, 64.6, 64.6, 64.6, 64.6, 64.6, 64.6, 64.6, 64.6, 64.7, 64.7, 64.7,
	64.7, 64.7, 64.7, 64.7, 64.7, 64.7, 64.7, 64.7, 64.7, 64.7, 64.7, 64.8, 64.8,
	64.8, 64.8, 64.8, 64.8, 64.8, 64.8, 64.8, 64.8, 64.8, 64.8, 64.8, 64.8, 64.9,
	64.9, 64.9, 64.9, 64.9, 64.9, 64.9, 64.9, 64.9, 64.9, 64.9, 64.9, 64.9, 64.9,
	64.9, 64.9, 64.9, 64.9, 64.9, 64.9, 64.9, 65, 65, 65, 65, 65, 65, 65, 65, 65,
	65, 65, 65, 65, 65, 65.1, 65.1, 65.1, 65.1, 65.1, 65.1, 65.1, 65.1, 65.1,
	65.1, 65.1, 65.1, 65.1, 65.1, 65.1, 65.1, 65.1, 65.1, 65.1, 65.1, 65.1, 65.1,
	65.1, 65.1, 65.1, 65.1, 65.1, 65.1, 65.2, 65.2, 65.2, 65.2, 65.2, 65.2, 65.2,
	65.2, 65.2, 65.2, 65.2, 65.2, 65.2, 65.2, 65.2, 65.2, 65.2, 65.2, 65.2, 65.2,
	65.2, 65.3, 65.3, 65.3, 65.3, 65.3, 65.3, 65.3, 65.3, 65.3, 65.3, 65.3, 65.3,
	65.3, 65.3, 65.3, 65.3, 65.3, 65.3, 65.3, 65.3, 65.3, 65.4, 65.4, 65.4, 65.4,
	65.4, 65.4, 65.4, 65.4, 65.4, 65.4, 65.4, 65.4, 65.4, 65.4, 65.4, 65.4, 65.4,
	65.4, 65.4, 65.4, 65.4, 65.4, 65.4, 65.4, 65.4, 65.4, 65.4, 65.4, 65.5, 65.5,
	65.5, 65.5, 65.5, 65.5, 65.5, 65.5, 65.5, 65.5, 65.5, 65.5, 65.5, 65.5, 65.5,
	65.5, 65.5, 65.5, 65.5, 65.5, 65.5, 65.6, 65.6, 65.6, 65.6, 65.6, 65.6, 65.6,
	65.6, 65.6, 65.6, 65.6, 65.6, 65.6, 65.6, 65.6, 65.6, 65.6, 65.6, 65.6, 65.6,
	65.6, 65.7, 65.7, 65.7, 65.7, 65.7, 65.7, 65.7, 65.7, 65.7, 65.7, 65.7, 65.7,
	65.7, 65.7, 65.7, 65.7, 65.7, 65.7, 65.7, 65.7, 65.7, 65.7, 65.7, 65.7, 65.7,
	65.7, 65.7, 65.7, 65.8, 65.8, 65.8, 65.8, 65.8, 65.8, 65.8, 65.8, 65.8, 65.8,
	65.8, 65.8, 65.8, 65.8, 65.8, 65.8, 65.8, 65.8, 65.8, 65.8, 65.8, 65.9, 65.9,
	65.9, 65.9, 65.9, 65.9, 65.9, 65.9, 65.9, 65.9, 65.9, 65.9, 65.9, 65.9, 65.9,
	65.9, 65.9, 65.9, 65.9, 65.9, 65.9, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66,
	66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66.1,
	66.1, 66.1, 66.1, 66.1, 66.1, 66.1, 66.1, 66.1, 66.1, 66.1, 66.1, 66.1, 66.1,
	66.2, 66.2, 66.2, 66.2, 66.2, 66.2, 66.2, 66.2, 66.2, 66.2, 66.2, 66.2, 66.2,
	66.2, 66.2, 66.2, 66.2, 66.2, 66.2, 66.2, 66.2, 66.2, 66.2, 66.2, 66.2, 66.2,
	66.2, 66.2,
];

const AverageEggWeightForBrownLayingHensProgram = () => {
	const [age, setAge] = useState("");
	const [week, setWeek] = useState("");
	const [result, setResult] = useState("");

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const handleCalculate = () => {
		const ageValue = parseInt(age, 10);
		if (ageValue < 120 || ageValue > 658) {
			alert(newLang.MinimumAgeMaximumAge); // Localized message
			return;
		}

		const weeks = Math.ceil(ageValue / 7);
		setWeek(weeks);

		const index = ageValue - 120;
		const eggWeight = eggWeights[index];

		if (eggWeight !== undefined) {
			const res1 = eggWeight;
			const res2 = (res1 * 30) / 1000;

			setResult(
				`${newLang.NormalEggWeight} = ${res1} ${newLang.gram}.\n\n${newLang.EggTrayWeight} = ${res2} kg.`
			);
		} else {
			setResult(lang.EggWeightDataNotAvailable);
		}
	};

	useEnterKeyPress(handleCalculate); // Ensure this hook triggers on Enter

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.brownLayingHensManagmentPrograms
						.AverageEggWeightForBrownLayingHensProgram
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${newLang.Age}:`}
						value={age}
						onChange={(e) => setAge(e.target.value)}
						placeholder={lang.Enterageofbirds}
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={handleCalculate}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={
							"/assets/icons/Average Egg Weight For Brown Laying Hens Program.webp"
						}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>

			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
		</section>
	);
};

export default AverageEggWeightForBrownLayingHensProgram;
