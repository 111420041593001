import React, { useEffect, useRef, useState } from "react";
import "./Navbar.scss";
import { Link } from "react-router-dom";
import ar from "../../lang/ar/ar.json";
import en from "../../lang/en/en.json";
import fr from "../../lang/fr/fr.json";
import { useSelector } from "react-redux";
import ChangeLang from "../../utils/ChangeLang";
import { MdArrowDropDown } from "react-icons/md";

const Navbar = () => {
	const [isHovered, setIsHovered] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const timeoutRef = useRef(null);
	const navbarRef = useRef(null);

	const handleMouseEnter = () => {
		clearTimeout(timeoutRef.current);
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		timeoutRef.current = setTimeout(() => {
			setIsHovered(false);
		}, 300);
	};

	const handleClickOutside = (event) => {
		if (navbarRef.current && !navbarRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	const handleToggleClick = () => {
		setIsOpen(!isOpen);
	};

	const handleLinkClick = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en" ? en.navbar : language === "ar" ? ar.navbar : fr.navbar;

	const navbarItems = [
		{
			name: lang.home,
			path: "/",
		},
		{
			name: lang.services,
			path: "/services",
		},
		{
			name: lang.blogs,
			path: "/blogs",
		},
		{
			name: lang.vedios,
			path: "/videos",
		},
		// {
		// 	name: lang.jobs,
		// 	path: "/jobs",
		// },
		// {
		// 	name: lang.myAccount,
		// 	path: "/my-account",
		// },
		{
			name: lang.about,
			path: "/about",
		},
		{
			name: lang.contact,
			path: "/contact",
		},
	];

	const mainServices = [
		{
			id: 1,
			service: lang.Broilers_Managment,
			route: "/services/broilers-management",
			sub: [
				{
					id: 1,
					link: "Broilers Managment Program",
					path: "/",
				},
				{
					id: 2,
					link: "Housing Desnity Program",
					path: "/",
				},
				{
					id: 3,
					link: "Feed Consumption Rate Program",
					path: "/",
				},
				{
					id: 4,
					link: "Water Consumption Rate Program",
					path: "/",
				},
				{
					id: 5,
					link: "Total Feed Consumption Rate Program",
					path: "/",
				},
				{
					id: 6,
					link: "Feed Convertion Ratio FCR Program",
					path: "/",
				},
				{
					id: 7,
					link: "Normal Body Weight Program",
					path: "/",
				},
				{
					id: 8,
					link: "Livability Percentage Program",
					path: "/",
				},
				{
					id: 9,
					link: "Production Guide Program",
					path: "/",
				},
				{
					id: 10,
					link: "European production Efficiency Factor Program",
					path: "/",
				},
				{
					id: 11,
					link: "Lighting Rate Program",
					path: "/",
				},
				{
					id: 12,
					link: "Ventilation Rate Program",
					path: "/",
				},
				{
					id: 13,
					link: "Amount Of Water Needed For Vaccination Program",
					path: "/",
				},
				{
					id: 14,
					link: "Normal Mortality Rate Program",
					path: "/",
				},
			],
		},
		{
			id: 2,
			service: lang.Drug_Interactions,
			route: "/services/drug-interactions",
			sub: [
				{
					id: 1,
					link: "Drug Interactions Program",
					path: "Drug Interactions Program",
				},
				{
					id: 2,
					link: "Drug Dose Programs",
					path: "Drug Dose Programs",
				},
			],
		},
		{
			id: 3,
			service: lang.Layers_Managment,
			route: "/services/layers-management",
			sub: [
				{
					id: 1,
					link: "White Pullets Managment Programs",
					path: "White Pullets Managment Programs",
				},
				{
					id: 2,
					link: "White Laying Hens Managment Programs",
					path: "White Laying Hens Managment Programs",
				},
				{
					id: 3,
					link: "Brown Pullets Managment Programs",
					path: "Brown Pullets Managment Programs",
				},
				{
					id: 2,
					link: "Brown Laying Hens Managment Programs",
					path: "Brown Laying Hens Managment Programs",
				},
			],
		},
		{
			id: 4,
			service: lang.Feed_Analysis,
			route: "/services/feed-analysis",
			sub: [
				{
					id: 1,
					link: "Poultry Feed Analysis Program",
					path: "Poultry Feed Analysis Program",
				},
				{
					id: 2,
					link: "Animal Feed Analysis Program",
					path: "Animal Feed Analysis Program",
				},
			],
		},
	];
	console.log("isHovered", isHovered);
	return (
		<div
			className='main-nav'
			dir={language === "ar" && "rtl"}
		>
			<div className='container'>
				<nav
					className='navbar navbar-expand-lg'
					ref={navbarRef}
				>
					<div className='container-fluid'>
						{/* Logo */}
						<Link
							className='navbar-brand'
							to='/'
						>
							<img
								src={"/assets/logo.png"}
								alt='logo'
								style={{ maxHeight: "80px" }}
							/>
						</Link>
						<button
							className='navbar-toggler'
							type='button'
							onClick={handleToggleClick}
							aria-controls='navbarSupportedContent'
							aria-expanded={isOpen}
							aria-label='Toggle navigation'
						>
							<span className='navbar-toggler-icon'></span>
						</button>
						<div
							className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
							id='navbarSupportedContent'
						>
							{/* Navbar Link */}
							<ul className='navbar-nav m-auto mb-2 mb-lg-0'>
								{navbarItems.map((navSingle) => (
									<li
										className={`nav-item ${
											navSingle.path === "/services" ? "services-li" : ""
										}`}
										key={navSingle.name}
									>
										{navSingle.path === "/services" ? (
											<div
												className='parent-services-nav'
												onMouseEnter={handleMouseEnter}
												onMouseLeave={handleMouseLeave}
											>
												<Link
													className='nav-link'
													to={navSingle.path}
													onClick={handleLinkClick}
												>
													{navSingle.name} <MdArrowDropDown />
												</Link>
												<ul
													style={{ display: isHovered ? "block" : "none" }}
													className='parent-nav-link'
												>
													{mainServices.map((service) => (
														<li
															className='menu-sub-link'
															key={service.id}
														>
															<Link
																to={service.route}
																onClick={handleLinkClick}
																className='  user-select-none'
															>
																{service.service}
															</Link>
														</li>
													))}
												</ul>
											</div>
										) : (
											<Link
												className='nav-link user-select-none'
												to={navSingle.path}
												onClick={handleLinkClick}
											>
												{navSingle.name}
											</Link>
										)}
									</li>
								))}
							</ul>

							{/* Navbar Button */}
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									gap: "10px",
								}}
							>
								<div>
									<ChangeLang
										en={"English"}
										ar={"عربي"}
										fr={"French"}
										handleLinkClick={handleLinkClick}
									/>
								</div>
								<div className='theme-btn '>
									<Link
										to='/login'
										onClick={handleLinkClick}
									>
										{lang.login}
									</Link>
								</div>
							</div>
						</div>
					</div>
				</nav>
			</div>
		</div>
	);
};

export default Navbar;
