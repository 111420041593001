import React from "react";
import MainFourServisesLayout from "../../../../components/MainFourServisesLayout/MainFourServisesLayout";

import ar from "../../../../lang/ar/ar.json";
import en from "../../../../lang/en/en.json";
import fr from "../../../../lang/fr/fr.json";
import { useSelector } from "react-redux";

function BrownPulletsManagmentPrograms() {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.layersManagment.brownPulletsManagmentPrograms
			: language === "ar"
			? ar.services.layersManagment.brownPulletsManagmentPrograms
			: fr.services.layersManagment.brownPulletsManagmentPrograms;

	const brownPulletsManagmentProgramsServices = [
		{
			id: 1,
			title: lang.RearingGuideforBrownPulletsProgram,
			description: lang.RearingGuideforBrownPulletsProgram,
			imgSrc: "/assets/icons/Normal Body Weight For Brown Pullets Program.webp",
			link: "rearing-guide-for-brown-pullets-program",
		},
		{
			id: 2,
			title: lang.WaterConsumptionRateForBrownpulletsPrograms,
			description: lang.WaterConsumptionRateForBrownpulletsPrograms,
			imgSrc:
				"/assets/icons/Water Consumption Rate For Brown Pullets Programs.webp",
			link: "water-consumption-rate-for-brown-pullets-program",
		},
		{
			id: 3,
			title: lang.FeedConsumptionRateForBrownpulletsPrograms,
			description: lang.FeedConsumptionRateForBrownpulletsPrograms,
			imgSrc:
				"/assets/icons/Feed Consumption Rate For Brown Pullets Programs.webp",
			link: "feed-consumption-rate-for-brown-pullets-program",
		},
		{
			id: 4,
			title: lang.TotalFeedConsumptionForBrownPulletsProgram,
			description: lang.TotalFeedConsumptionForBrownPulletsProgram,
			imgSrc:
				"/assets/icons/Total Feed Consumption For Brown Pullets Program.webp",
			link: "total-feed-consumption-rate-for-brown-pullets-program",
		},
		{
			id: 5,
			title: lang.FeedConvertionRatioFCRForBrownPulletsProgram,
			description: lang.FeedConvertionRatioFCRForBrownPulletsProgram,
			imgSrc: "/assets/icons/Feed Convertion Ratio FCR Program.webp",
			link: "feed-convertion-ratio-FCR-for-brown-pullets-program",
		},
		{
			id: 6,
			title: lang.NormalBodyWeightForBrownPulletsProgram,
			description: lang.NormalBodyWeightForBrownPulletsProgram,
			imgSrc: "/assets/icons/Normal Body Weight For Brown Pullets Program.webp",
			link: "normal-body-weight-for-brown-pullets-program",
		},
	];

	return (
		<MainFourServisesLayout
			serviceData={brownPulletsManagmentProgramsServices}
			title={lang.title}
			isShowSuggestedServices={false}
		/>
	);
}

export default BrownPulletsManagmentPrograms;
