import React from "react";
import { Link } from "react-router-dom";
import "./AboutBanner.scss";
// import bannerOne from "../../public/assets/about/banner/banner_1.png";
// import bannerTwo from "../../public/assets/about/banner/banner_2.png";
// import pattern from "../../public/assets/banner/pattern.png";

const AboutBanner = () => {
	return (
		<section
			className='about-section'
			data-aos='fade-up'
			data-aos-duration='2000'
		>
			<div className='d-table'>
				<div className='d-table-cell'>
					<div className='container'>
						<div className='row align-items-center'>
							<div className='col-lg-5'>
								<div className='about-banner-text'>
									<h2>About Us</h2>
									<p>
										We want you to feel amazing about your oral wellness. Not
										just twice a year, but every time you take a bite, tell a
										joke, laugh, or share a kiss.
									</p>

									<div className='theme-btn'>
										<Link to='/'>Contact Us</Link>
									</div>
								</div>
							</div>
							<div className='col-lg-7'>
								<div className='about-banner-img'>
									<img
										src={"/assets/about/banner/banner_1.png"}
										alt='about banner'
									/>
									<img
										src={"/assets/about/banner/banner_2.png"}
										alt='about banner two'
									/>
									<img
										className='pattern'
										src={"/assets/banner/pattern.png"}
										alt='about banner two'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutBanner;
